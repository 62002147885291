import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "internationalization-i18n",
      "style": {
        "position": "relative"
      }
    }}>{`Internationalization (i18n)`}</h1>
    <p>{`The components use the user browser language to determine the locale.`}</p>
    <p>{`You can customize the strings used by the components using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`LocaleContent.Provider`}</code>{` component and access them using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useLocale`}</code>{` hook.`}</p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useLocale`}</code>{` hook returns a map of strings used internally by some bold components, like Calendar, Paginator etc.`}</p>
    <Demo src='pages/usage/i18n/LocaleProvider' mdxType="Demo" />
    <h2 {...{
      "id": "available-locales",
      "style": {
        "position": "relative"
      }
    }}>{`Available locales`}</h2>
    <p>{`Check the available locales on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`src/i18n/locales`}</code>{` folder. Feel free to open a pull request if your locale is not there yet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      